@import '../../../node_modules/antd/dist/antd.less';

.App {
  text-align: center;
}

.layout-content {
  min-height: 100vh;
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  padding: 20px 0;
}
.layout-sider {
  min-height: 100vh;
}

.layout-logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  line-height: 31px;
  font-weight: bold;
  text-align: center;
  float: left;
  color: #fff;
}
.layout-menu-logout {
  position: absolute;
  right: 0;
}
.layout-header-role-admin {
  background-color: #841805;
  ul {
    background-color: #841805 !important;;
  }
  .ant-menu-item-selected {
    background-color: #841805 !important;;
  }
}

@primary-color: #e32328;@layout-header-background: #243665;